import styled from 'styled-components';
import { BlockTitle } from '@components/ui/block-title/block-title';
import { IconButton } from '@components/ui/icon-button/icon-button';
import { device, scrollbar, theme } from '@components/ui/theme';
import { StyledProductCard } from '@components/product/elements/product-card/product-card.style';

export const StyledSimilarProducts = styled.section`
  ${BlockTitle} {
    margin-top: 80px;
    margin-bottom: 40px;
  }

  @media ${device.tabletM} {
    // Включает скролл вместо слайдера
    padding-bottom: 24px;
    overflow-x: scroll;
    ${scrollbar.vertical}
    ${scrollbar.alignSidesDesktop};
    ${scrollbar.border.desktop}
    display: flex;
    gap: 20px;
  }

  @media (max-width: 600px) {
    ${scrollbar.alignSidesMobile};
    ${scrollbar.border.mobile}
    padding: 0 20px 20px 20px;
  }

  @media (max-width: 550px) {
    gap: 16px;
  }

  // @media (max-width: 1000px) {
  //   ${StyledProductCard} {
  //     min-width: 31%;
  //   }
  // }

  @media (max-width: 882px) {
    ${StyledProductCard} {
      min-width: 36%;
    }
  }

  @media (max-width: 744px) {
    ${StyledProductCard} {
      min-width: 37%;
    }
  }

  @media (max-width: 680px) {
    ${StyledProductCard} {
      min-width: 42%;
    }
  }

  @media (max-width: 512px) {
    ${StyledProductCard} {
      min-width: 52%;
    }
  }

  @media (max-width: 418px) {
    ${StyledProductCard} {
      min-width: 65%;
    }
  }
`;

export const SimilarProductsContainer = styled.div<{ marginFromSlider?: boolean }>`
  margin-top: ${({ marginFromSlider }) => (marginFromSlider ? '50px' : '0')};

  ${StyledSimilarProducts} {
    margin-top: ${({ marginFromSlider }) => (marginFromSlider ? '24px' : '0')};
  }

  @media (max-width: 480px) {
    margin-top: ${({ marginFromSlider }) => (marginFromSlider ? '35px' : '0')};

    ${StyledSimilarProducts} {
      margin-top: ${({ marginFromSlider }) => (marginFromSlider ? '25px' : '0')};
    }
  }
`;

export const PrevProduct = styled(IconButton)`
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(99, 47, 117, 0.14);
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -7px;
  margin: auto;
  z-index: 2;

  &:disabled {
    display: none;
  }

  &:hover:not(:disabled) div {
    color: ${theme.colors.primary};
  }
`;

export const NextProduct = styled(IconButton)`
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(99, 47, 117, 0.14);
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -18px;
  margin: auto;
  z-index: 2;

  &:disabled {
    display: none;
  }

  &:hover:not(:disabled) div {
    color: ${theme.colors.primary};
  }
`;

export const Slider = styled.div`
  position: relative;
  margin: -13px 0 -13px -13px;

  .swiper {
    &:not(.swiper-initialized) {
      .swiper-wrapper {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;

        max-height: 385px;
        overflow: hidden;
      }
    }
  }

  @media (max-width: 1440px) {
    ${PrevProduct} {
      left: 9px;
    }
    ${NextProduct} {
      right: -6px;
    }
  }

  @media (max-width: 1457px) {
    .swiper {
      &:not(.swiper-initialized) {
        .swiper-wrapper {
          grid-template-columns: repeat(5, 1fr);
          gap: 20px;
        }
      }
    }
  }

  @media (max-width: 1281px) {
    .swiper {
      &:not(.swiper-initialized) {
        .swiper-wrapper {
          grid-template-columns: repeat(4, 1fr);
          gap: 20px;
        }
      }
    }
  }

  @media (min-width: 1001px) {
    ${StyledProductCard} {
      width: auto;
    }

    .swiper {
      padding: 13px;
      width: calc(100% + 13px);
    }
  }
`;
