import React, { useRef, useState } from "react";
import styles from "./product-property.module.css";
import { Popover } from "@components/ui/popover/popover";
import { observer } from "mobx-react-lite";
import { useStore } from "@stores/root";

type Props = {
  name: string;
  value: string;
};

export const ProductProperty = observer((props: Props) => {
  const { ui } = useStore()
  const valueRef = useRef<HTMLSpanElement | null>(null);
  const [isOverflowing, setIsOverflowing] = useState(true);

  React.useEffect(() => {
    const isOverflowing = valueRef.current?.offsetWidth > 227;
    setIsOverflowing(isOverflowing);
  }, []);

  return (
    <div className={styles.property}>
      <div className={styles.name}><span>{props.name}</span></div>
      <div className={styles.dots} />
      <Popover zIndex={1} placement='top' align='right' disabled={!isOverflowing} target={
        <div className={styles.value}>
          <span ref={valueRef}>{props.value}</span>
        </div>
      } >
        <div className={styles.popover}>
          <span>{props.value}</span>
        </div>
      </Popover>
    </div>
  );
})
