import { ProductProperty } from "@components/product/elements/product-property/product-property";
import React from "react";
import { Product } from "../../../../gql/generated";
import { ProductPropertyDetails } from "@components/product/elements/product-property-details/product-property-details";

type Props = {
  attributes: Product['attributes'];
  details?: boolean;
};

export const ProductProperties = (props: Props) => {
  const Component = props.details ? ProductPropertyDetails : ProductProperty;
  return (
    <div>
      {props.attributes.map((attribute) => (
        <Component key={attribute.name} {...attribute} />
      ))}
    </div>
  );
};
