import styled from 'styled-components';
import { theme } from '@components/ui/theme';

export const ProductPropertyDetailMain = styled.div`
  display: flex;
  min-width: 539px;

  @media (max-width: 1000px) {
    min-width: 456px;
  }

  @media (max-width: 768px) {
    min-width: 375px;
  }

  @media (max-width: 686px) {
    min-width: 300px;
  }
`;

export const ProductPropertyDetailName = styled.div`
  font-size: 14px;
  color: #9ca0a9;
  line-height: 140%;
  min-width: fit-content;
`;

export const ProductPropertyDetailDots = styled.div`
  margin: 0 4px;
  border-bottom: 1px dotted #d9dde7;
  flex: 1;
  height: 15px;
`;

export const ProductPropertyDetailValue = styled.div`
  font-size: 14px;
  line-height: 140%;
  color: ${theme.colors.black};
  overflow: hidden;
  max-width: 100%;
`;

export const StyledProductPropertyDetail = styled.div`
  display: flex;
  align-items: flex-start;
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 14px;
    }

    ${ProductPropertyDetailMain} {
      min-width: unset;
    }
    ${ProductPropertyDetailDots} {
      display: none;
    }
    ${ProductPropertyDetailName} {
      margin-bottom: 4px;
    }
    ${ProductPropertyDetailValue} {
      font-size: 14px;
    }
  }
`;
