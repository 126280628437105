import React from "react";
import * as S from "./product-property-details.style";

type Props = {
  name: string;
  value: string;
};

export const ProductPropertyDetails = (props: Props) => {


  return (
    <S.StyledProductPropertyDetail>
      <S.ProductPropertyDetailMain>
        <S.ProductPropertyDetailName>{props.name}</S.ProductPropertyDetailName>
        <S.ProductPropertyDetailDots />
      </S.ProductPropertyDetailMain>

      <S.ProductPropertyDetailValue>{props.value}</S.ProductPropertyDetailValue>
    </S.StyledProductPropertyDetail>
  );
};
