import React from "react";
import * as S from "./properties-slider-style";
import { Product } from "../../../../gql/generated";
import { observer } from "mobx-react-lite";
import { useStore } from "@stores/root";

type Props = {
  properties: Product['attributes'];
};

export const PropertiesSlide: FCC<Props> = observer(({ properties }) => {
  const { ui } = useStore()

  return (
    <S.StyledPropertiesSlide className={ui.viewport <= 480 && 'single'}>
      {properties.map((property) => {
        return (
          <S.PropertiesSlideAttr key={`Properties-${Math.random()}-${Math.random()}`}>
            <S.PropertiesSlideAttrTitle>{property.name}</S.PropertiesSlideAttrTitle>
            <S.PropertiesSlideAttrValue>{property.value}</S.PropertiesSlideAttrValue>
          </S.PropertiesSlideAttr>
        );
      })}
    </S.StyledPropertiesSlide>
  );
});
