import React, { useRef, useState } from 'react';
import * as S from './product-cards-slider.style';
import { BlockTitle } from '@components/ui/block-title/block-title';
import { Icon } from '@components/ui/icons/icon';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  ProductCard,
  ProductCardType,
} from '@components/product/elements/product-card/product-card';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores/root';

type Props = {
  products: ProductCardType[];
  subtitle?: string;
  title?: string;
  excludeIDs?: string[];
  marginFromSlider?: boolean;
  useFourSlides?: boolean;
};

export const ProductCardsSlider = observer((props: Props) => {
  const prevButton = useRef<HTMLButtonElement | null>(null);
  const nextButton = useRef<HTMLButtonElement | null>(null);

  const { ui } = useStore();
  const [excluded, _] = useState(new Set(props.excludeIDs));

  if (props.products.length === 0 || !props.products) return null;

  if (ui.viewport <= 1000) {
    return (
      <S.SimilarProductsContainer marginFromSlider={props.marginFromSlider}>
        {props.title && <BlockTitle subtitle={props.subtitle}>{props.title}</BlockTitle>}
        <S.StyledSimilarProducts>
          {props.products.map(
            (product) =>
              !excluded.has(product.id) && (
                <ProductCard key={`responsive-${product.id}`} product={product} />
              )
          )}
        </S.StyledSimilarProducts>
      </S.SimilarProductsContainer>
    );
  }

  return (
    <S.StyledSimilarProducts>
      {props.title && <BlockTitle subtitle={props.subtitle}>{props.title}</BlockTitle>}
      <S.Slider>
        <S.PrevProduct ariaLabel="Назад" disabled width={38} height={38} ref={prevButton}>
          <Icon icon="chevronLeft" />
        </S.PrevProduct>

        <S.NextProduct ariaLabel="Вперед" width={38} height={38} ref={nextButton}>
          <Icon icon="chevronRight" />
        </S.NextProduct>

        <Swiper
          slidesPerView={(ui.viewport > 1440 && props.useFourSlides) || ui.viewport <= 1264 ? 4 : 5}
          spaceBetween={20}
          navigation={{
            nextEl: nextButton.current,
            prevEl: prevButton.current,
          }}
          onBeforeInit={(swiper: any) => {
            swiper.params.navigation.prevEl = prevButton.current;
            swiper.params.navigation.nextEl = nextButton.current;
          }}
        >
          {props.products.map(
            (product) =>
              !excluded.has(product.id) && (
                <SwiperSlide key={product.id}>
                  <ProductCard product={product} />
                </SwiperSlide>
              )
          )}
        </Swiper>
      </S.Slider>
    </S.StyledSimilarProducts>
  );
});
