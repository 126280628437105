import styled from 'styled-components';
import { scrollbar } from '@components/ui/theme';

export const StyledPropertiesSlider = styled.div`
  overflow: scroll;
  ${scrollbar.disable};
  ${scrollbar.alignSidesMobile};
  max-width: 99vw;
  display: flex;
`;

export const StyledPropertiesSlide = styled.div`
  min-width: 280px;
  padding: 20px 38px 30px 20px;
  background-color: #f9f7fa;

  &.single {
    min-width: 100%;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const PropertiesSlideAttr = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const PropertiesSlideAttrTitle = styled.div`
  margin-bottom: 5px;
  color: #686a70;
  font-size: 14px;
  line-height: 19px;
`;

export const PropertiesSlideAttrValue = styled.div`
  font-size: 14px;
  line-height: 140%;
  color: #302e30;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
