import styled from 'styled-components';
import { theme } from '@components/ui/theme';

export const DescriptionOuterBlock = styled.div`
  margin-top: 10px;
`;

export const Description = styled.div`
  margin-top: 25px;
  font-size: 14px;
  line-height: 160%;
  color: #686a70;

  ul {
    list-style: initial;
    margin: initial;
    padding: 0 0 0 40px;
  }

  span {
    div {
      margin-top: 10px;
    }
  }

  .showMore {
    color: ${theme.colors.primary};
    cursor: pointer;
    border-bottom: 1px dotted ${theme.colors.primary};
    font-size: 15px;

    &:hover {
      border-color: transparent;
    }
  }

  @media (max-width: 480px) {
    .showMore {
      display: block;
      height: 40px;
      text-align: center;
      cursor: pointer;
      padding: 11.5px 0;
      background: #f9f7fa;
      border-radius: 3px;
      font-size: 14px;
      border: none;
      color: #302e30;
      line-height: 120%;
      width: 100%;
    }

    ${DescriptionOuterBlock} {
      margin-top: 20px;
    }
  }
`;
