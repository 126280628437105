import React from "react";
import * as S from "./properties-slider-style";
import { Product } from "../../../../gql/generated";
import { PropertiesSlide } from "@components/product/blocks/properties-slider/properties-slide";

type Props = {
  properties: Product['attributes'];
};

export const PropertiesSlider: FCC<Props> = (props) => {
  return (
    <S.StyledPropertiesSlider>
      <PropertiesSlide
        properties={props.properties.slice(0,5)}
      />
    </S.StyledPropertiesSlider>
  );
};
