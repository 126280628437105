import React, { useState } from "react";
import * as S from "./description.style";
import { formatDescription, formatEllipsis } from "../../../../utils/formaters";

type Props = {
  text: string;
  lettersCount?: number
};

export const ProductDescription = (props: Props) => {
  const formattedText = formatDescription(props.text);
  const [textToShow, setTextToShow] = useState(formatEllipsis(formattedText, props.lettersCount ?? 700));
  const [readAll, setReadAll] = useState(false);

  const showMore = () => {
    setTextToShow(formattedText);
    setReadAll(true);
  };

  const textLeft = formattedText.length > textToShow.length;

  return (
    <S.Description>
      <span dangerouslySetInnerHTML={{ __html: formattedText }} />
      {/* {
        !readAll && (
          <S.DescriptionOuterBlock>
              <span className="showMore" onClick={showMore}>
                Читать полностью
              </span>
          </S.DescriptionOuterBlock>
        )
      } */}
    </S.Description>
  );
};
