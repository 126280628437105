import { IconMessage } from '@components/ui/icons/icon-message';
import { TextWithIcon } from '@components/ui/text-with-icon/text-with-icon';
import React from 'react';
import { ProductProperties } from '../properties/properties';
import styles from './information.module.css';
import plural from 'plural-ru';
import { Product } from '../../../../gql/generated';
import Link from 'next/link';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores/root';
import { PropertiesSlider } from '@components/product/blocks/properties-slider/properties-slider';

type Props = {
  attributes: Product['attributes'];
  reviewsCount: number;
  slug: string;
  tinyTitle?: boolean;
  useDivAsTitle?: boolean;
};

const TitleComponent: FCC<{ useDivAsTitle?: boolean }> = (props) => {
  if (props.useDivAsTitle) return <div className={styles.title}>{props.children}</div>;

  return <h2 className={styles.title}>{props.children}</h2>;
};

export const ProductInformation = observer((props: Props) => {
  const { ui } = useStore();
  const reviewsPlural = plural(props.reviewsCount, '%d отзыв', '%d отзыва', '%d отзывов');

  return (
    <section className={`${styles.information} info ${props.tinyTitle && styles.tiny}`}>
      <div className={`${styles.titleContainer}`}>
        <TitleComponent useDivAsTitle={props.useDivAsTitle}>Характеристики</TitleComponent>
        <div className={`${styles.more} ${styles.responsive}`}>
          <Link legacyBehavior prefetch={false} href={`/p/${props.slug}/details`} passHref>
            <a>Все характеристики</a>
          </Link>
          {props.reviewsCount > 0 && (
            <Link legacyBehavior prefetch={false} href={`/p/${props.slug}/reviews`} passHref>
              <TextWithIcon icon={IconMessage}>{reviewsPlural}</TextWithIcon>
            </Link>
          )}
        </div>
      </div>

      <div className={styles.properties}>
        {ui.viewport > 480 ? (
          <ProductProperties attributes={props.attributes.slice(0, 8)} />
        ) : (
          <PropertiesSlider properties={props.attributes} />
        )}
      </div>

      <div className={styles.more}>
        <Link legacyBehavior prefetch={false} href={`/p/${props.slug}/details`} passHref>
          <a>Все характеристики</a>
        </Link>
        {props.reviewsCount > 0 && (
          <Link legacyBehavior prefetch={false} href={`/p/${props.slug}/reviews`} passHref>
            <TextWithIcon icon={IconMessage}>{reviewsPlural}</TextWithIcon>
          </Link>
        )}
      </div>

      <div className={styles.warning}>
        <span>*</span> Информация о технических характеристиках, комплекте поставки, стране
        изготовления и внешнем виде товара носит справочный характер и основывается на последних
        доступных сведениях от продавца.
      </div>
    </section>
  );
});
